<template>
  <div>
    <div style="display: flex; align-items: center; justify-content: space-between">
      <el-page-header @back="back" :content="skuId ? '编辑课程' : '创建课程'">
      </el-page-header>
      <el-button type="primary" @click="save"> 保存 </el-button>
    </div>
    <div class="container">
      <el-form v-model="courseDetail" label-width="120px">
        <!-- <el-form-item label="课程开始时间">
          <div style="display: flex">
            <el-date-picker
              v-model="courseDetail.startTime"
              type="datetime"
              value-format="timestamp"
              placeholder="选择日期时间"
            >
            </el-date-picker>
          </div>
        </el-form-item>
        <el-form-item label="课程结束时间">
          <div style="display: flex">
            <el-date-picker
              v-model="courseDetail.endTime"
              type="datetime"
              value-format="timestamp"
              placeholder="选择日期时间"
            >
            </el-date-picker>
          </div>
        </el-form-item> -->
        <el-form-item label="绑定商品" required>
          <div style="display: flex">
            <el-button @click="showDialog('spu')" :disabled="skuId != null">{{
                courseDetail.skuId || "去绑定商品"
              }}
            </el-button>
        </div>
        </el-form-item>
<!--        <el-form-item label="是否展示弹窗">-->
<!--          <div style="display: flex; margin-top: 10px">-->
<!--            <el-switch-->
<!--                v-model="courseDetail.showPopup">-->
<!--            </el-switch>-->
<!--          </div>-->
<!--        </el-form-item>-->
<!--        <el-form-item label="弹窗内容">-->
<!--          <div v-if="qrImageUrl && qrImageUrl.length" style="display: flex; position: relative">-->
<!--            <el-image-->
<!--                :src="qrImageUrl"-->
<!--                fit="contain"-->
<!--                :preview-src-list="[qrImageUrl]"-->
<!--                style="width: 150px; height: 150px"-->
<!--            >-->
<!--            </el-image>-->
<!--            <div class="image-hover">-->
<!--              <i class="el-icon-delete delete" @click="handleRemoveImage"></i>-->
<!--            </div>-->
<!--          </div>-->
<!--          <el-upload-->
<!--              v-else-->
<!--              class="uploader"-->
<!--              action="cover"-->
<!--              :before-upload="(file) => {-->
<!--                uploadImage(file);-->
<!--                return false-->
<!--              }"-->
<!--              v-loading="qrImageUrlLoading"-->
<!--          >-->
<!--            <i class="el-icon-plus uploader-icon"></i>-->
<!--          </el-upload>-->
<!--        </el-form-item>-->
        <el-form-item label="课程表（选填）">
          <div style="display: flex">
            <el-button type="default" @click="addCatalog">添加课表项</el-button>
          </div>
          <div style="display: flex; flex-wrap: wrap;">
            <el-card
              v-for="(item, index) in catalogList"
              :key="index"
              style="margin-top: 20px; width: 440px; margin-right: 50px"
            >
              <div slot="header" class="clearfix">
                <span>课表{{ index + 1 }}</span>
                <el-button
                  style="float: right; margin-left: 20px"
                  type="text"
                  @click="deleteCatalog(index)"
                  >移除</el-button
                >
                <el-button style="float: right" type="text" @click="addCatalogItem(item)"
                  >添加课表项详情</el-button
                >
              </div>
              <div style="display: flex; flex-direction: column">
                <div style="display: flex">
                  <span>课表标题：</span>
                  <el-input v-model="item.title" style="width: 200px"></el-input>
                </div>
                <div style="display: flex; margin-top: 20px">
                  <span>内容描述：</span>
                  <el-input v-model="item.content" style="width: 200px"></el-input>
                </div>
                <div style="display: flex; margin-top: 20px">
                  <span>课程时间：</span>
                  <el-date-picker
                    v-model="item.date"
                    type="datetime"
                    value-format="timestamp"
                    placeholder="选择日期时间"
                  >
                  </el-date-picker>
                </div>
              </div>

              <el-card
                v-for="(subItem, i) in item.catalogItemList"
                :key="i"
                style="margin-top: 20px"
              >
                <div slot="header">
                  <el-button
                    style="float: right; margin-top: -20px"
                    type="text"
                    @click="deleteCatalogItem(i, item)"
                    >移除</el-button
                  >
                </div>
                <div style="display: flex; flex-direction: column">
                  <div style="display: flex">
                    <span>标题：</span>
                    <el-input v-model="subItem.title" style="width: 200px"></el-input>
                  </div>
                  <div style="display: flex; margin-top: 20px">
                    <span>内容：</span>
                    <el-input v-model="subItem.content" style="width: 200px"></el-input>
                  </div>
                  <div style="display: flex; margin-top: 20px">
                    <span>开始时间：</span>
                    <el-date-picker
                      v-model="subItem.startTime"
                      type="datetime"
                      value-format="timestamp"
                      placeholder="选择日期时间"
                    >
                    </el-date-picker>
                  </div>
                  <div style="display: flex; margin-top: 20px">
                    <span>结束时间：</span>
                    <el-date-picker
                      v-model="subItem.endTime"
                      type="datetime"
                      value-format="timestamp"
                      placeholder="选择日期时间"
                    >
                    </el-date-picker>
                  </div>
                  <div style="display: flex; margin-top: 20px; align-items: center">
                    <span>是否直播：</span>
                    <el-radio v-model="subItem.live" :label="true">是</el-radio>
                    <el-radio v-model="subItem.live" :label="false">否</el-radio>
                  </div>
                </div>
              </el-card>
            </el-card>
          </div>
        </el-form-item>
        <el-form-item label="课程资源列表" required>
          <div style="display: flex">
            <el-button type="default" @click="addResource">添加课程资源</el-button>
          </div>
          <draggable v-model="resourceList">
            <transition-group style="display: flex; flex-wrap: wrap;" :animation="180">
              <el-card
                v-for="(resourceItem, resourceIndex) in resourceList"
                :key="resourceItem.sourceId || resourceIndex"
                style="margin-top: 20px; width: 400px; margin-right: 50px"
              >
                <div slot="header">
                  <el-button
                    style="float: right; margin-top: -20px"
                    type="text"
                    @click="deleteResource(resourceIndex)"
                    >移除</el-button
                  >
                </div>
                <div style="display: flex; flex-direction: column">
                  <div style="display: flex">
                    <span>标题：</span>
                    <el-input v-model="resourceItem.title" style="width: 200px"></el-input>
                  </div>
                  <div style="display: flex; margin-top: 20px">
                    <span>副标题：</span>
                    <el-input
                      v-model="resourceItem.subtitle"
                      style="width: 200px"
                    ></el-input>
                  </div>
                  <!-- <div style="display: flex; margin-top: 20px">
                    <span>开始时间：</span>
                    <el-date-picker
                      v-model="resourceItem.startTime"
                      type="datetime"
                      value-format="timestamp"
                      placeholder="选择日期时间"
                    >
                    </el-date-picker>
                  </div>
                  <div style="display: flex; margin-top: 20px">
                    <span>结束时间：</span>
                    <el-date-picker
                      v-model="resourceItem.endTime"
                      type="datetime"
                      value-format="timestamp"
                      placeholder="选择日期时间"
                    >
                    </el-date-picker>
                  </div> -->
                  <div style="display: flex; margin-top: 20px; align-items: center">
                    <span>资源类型：</span>
                    <el-radio v-model="resourceItem.resourceType" label="VIDEO"
                      >视频</el-radio
                    >
                    <el-radio v-model="resourceItem.resourceType" label="FILE"
                      >文件</el-radio
                    >
                  </div>
                  <div style="display: flex">
                    <el-button @click="selectResource(resourceIndex, resourceItem)">{{
                      resourceItem.sourceId || "去选择课程资源"
                    }}</el-button>
                  </div>
                </div>
              </el-card>
            </transition-group>
          </draggable>
        </el-form-item>
      </el-form>
    </div>
    <el-dialog
      :title="(dialogType === 'spu' || dialogType === 'spuPoints') ? '选择要绑定的商品' : '选择课程资源'"
      :visible.sync="dialogVisible"
    >
      <el-table
        highlight-current-row
        :data="dialogData"
        border
        :max-height="350"
        @current-change="selectData"
        v-loading="dialogLoading"
        style="margin-top: 20px"
      >
        <template v-if="dialogType === 'spu' || dialogType === 'spuPoints'">
          <el-table-column prop="id" label="商品ID"></el-table-column>
          <el-table-column prop="name" label="商品名称"></el-table-column>
          <el-table-column prop="logoUrl" label="商品封面图" align="center">
            <template slot-scope="scope">
              <el-avatar :size="60" shape="square" :src="scope.row.logoUrl" />
            </template>
          </el-table-column>
        </template>
        <template v-else>
          <el-table-column prop="id" label="资源ID"></el-table-column>
          <el-table-column prop="filename" label="资源名"></el-table-column>
        </template>
      </el-table>
      <el-pagination
        background
        layout="prev, pager, next"
        :total="totalRows"
        :current-page="page"
        :page-size="20"
        style="margin-top: 20px"
        @current-change="getDialogData"
      ></el-pagination>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="bind">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import CourseApi from "../../request/CourseApi";
import SpuApi from "../../request/SpuApi";
import FileUtil from "@/utils/FileUtil.js";
import draggable from 'vuedraggable'

export default {
  components: {
    draggable,
  },
  data() {
    return {
      skuId: null,
      courseDetail: {},
      spuList: [],
      catalogList: [],
      resourceList: [],
      dialogType: "",
      dialogVisible: false,
      dialogLoading: false,
      resourceType: "",
      resourceIndex: 0,
      page: 1,
      qrImageUrl: '',
      totalRows: 0,
      dialogData: [],
      selectedData: null,
      qrImageUrlLoading: false,
    };
  },
  mounted() {
    this.skuId = this.$route.query.skuId;
    if (this.skuId) {
      this.loadData();
    }
  },
  methods: {
    back() {
      this.$router.go(-1);
    },
    loadData() {
      CourseApi.detail({
        skuId: this.skuId,
      }).then((res) => {
        if (res.success) {
          this.courseDetail = res.data;
          this.catalogList = res.data.courseCatalogList;
          this.resourceList = res.data.courseResourceList;
        }
      });
    },
    getDialogData(page) {
      this.page = page;
      this.dialogLoading = true;
      if (this.dialogType === "spu" || this.dialogType === 'spuPoints') {
        SpuApi.myList({
          pageNo: this.page,
          pageSize: 20,
          pointsGoods: this.dialogType === 'spuPoints',
          spuType: "COURSE_GOODS",
        })
          .then((res) => {
            this.dialogLoading = false;
            if (res.success) {
              this.dialogData = res.data;
              this.totalRows = res.totalRows;
            }
          })
          .catch(() => {
            this.dialogLoading = false;
          });
      } else {
        if (this.resourceType === "VIDEO") {
          CourseApi.videoList({
            pageNo: this.page,
            pageSize: 20,
          })
            .then((res) => {
              this.dialogLoading = false;
              if (res.success) {
                this.dialogData = res.data;
                this.totalRows = res.totalRows;
              }
            })
            .catch(() => {
              this.dialogLoading = false;
            });
        } else {
          CourseApi.fileList({
            pageNo: this.page,
            pageSize: 20,
          })
            .then((res) => {
              this.dialogLoading = false;
              if (res.success) {
                this.dialogData = res.data;
                this.totalRows = res.totalRows;
              }
            })
            .catch(() => {
              this.dialogLoading = false;
            });
        }
      }
    },
    addCatalog() {
      this.catalogList.push({ catalogItemList: [] });
    },
    addCatalogItem(item) {
      item.catalogItemList.push({ live: false });
    },
    deleteCatalog(index) {
      this.catalogList.splice(index, 1);
    },
    deleteCatalogItem(index, item) {
      item.catalogItemList.splice(index, 1);
    },
    addResource() {
      this.resourceList.push({ resourceType: "VIDEO" });
    },
    deleteResource(index) {
      this.resourceList.splice(index, 1);
    },
    selectResource(index, val) {
      this.resourceType = val.resourceType;
      this.resourceIndex = index;
      this.showDialog("resource");
    },
    showDialog(type) {
      this.dialogType = type;
      this.dialogVisible = true;
      this.getDialogData(1);
    },
    selectData(val) {
      this.selectedData = val;
    },
    bind() {
      if (this.dialogType === "spu" || this.dialogType === "spuPoints") {
        this.courseDetail.skuId = this.selectedData.skuList[0].skuId;
      } else {
        this.resourceList[this.resourceIndex].sourceId = this.selectedData.id;
      }
      this.dialogVisible = false;
    },
    save() {
      if (!this.resourceList.length) {
        this.$message.info("课程资源必填");
        return;
      }
      if (!this.courseDetail.skuId) {
        this.$message.info("必须绑定商品");
        return;
      }
      this.courseDetail.courseCatalogList = this.catalogList;
      this.courseDetail.courseResourceList = this.resourceList.map((item, index) => {
        return {
          ...item,
          sort: index
        }
      });
      CourseApi.saveCourse(this.courseDetail).then((res) => {
        if (res.success) {
          this.$message.success("保存成功");
          this.back();
        }
      });
    },
    handleRemoveImage() {
      this.qrImageUrl = '';
      this.qrImageUrlLoading = false;
    },
    async uploadImage(file) {
      let fileType = "image";
      this.qrImageUrlLoading = true;
      const result = await FileUtil.uploadFile(
          {
            file: file,
            dir: 'qrImageUrl',
            rename: true,
            suffix: fileType === "video" ? "mp4" : "jpg",
          },
          this.$http
      );
      this.coverUploading = false;
      this.bannerUploading = false;
      this.detailUploading = false;
      if (result.success) {
        this.qrImageUrl = result.url;
      }
      return false;
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  position: fixed;
  overflow: auto;
  top: 140px;
  width: calc(100vw - 200px - 40px);
  height: calc(100vh - 140px);
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: "";
}
.clearfix:after {
  clear: both;
}


.uploader {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  // overflow: hidden;
  width: 150px;
  height: 150px;
}

.uploader:hover {
  border-color: #409eff;
}

.uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 150px;
  height: 150px;
  line-height: 150px;
  text-align: center;
}

.uploader-icon:hover {
  color: #409eff;
}

.image-hover {
  position: absolute;
  width: 150px;
  height: 35px;
  bottom: 0;
  .delete {
    display: none;
  }
  &:hover {
    background-color: rgba($color: #000000, $alpha: 0.3);
    .delete {
      display: block;
      position: absolute;
      left: 0;
      right: 0;
      bottom: 5px;
      color: white;
      font-size: 24px;
    }
  }
}

</style>
